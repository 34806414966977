import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user";
import blog from "./slices/blog";

const store = configureStore({
  reducer: {
    user,
    blog,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
