import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const getHeaders = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
};

const axiosInstance = axios.create({
  baseURL: apiEndpoint,
  getHeaders,
});

export const signIn = (email, password) => {
  const data = axiosInstance.post("/admin_login", {
    email,
    password,
  });
  return data;
};

export const refreshToken = (data) => {
  return axiosInstance.post("/refresh", data);
};

export const getAllPost = (data) => {
  return axiosInstance.post("/returnBlogs", data);
};

export const createPost = (data) => {
  return axiosInstance.post("/createBlog", data);
};
export const editPost = (data) => {
  return axiosInstance.post("/editBlog", data);
};

export const returnSinglePost = (data) => {
  return axiosInstance.post("/returnSingleBlog", data);
};
export const returnAllTags = (data) => {
  return axiosInstance.post("/returnAllSubCategories", data);
};

export const deletePost = (data) => {
  return axiosInstance.post("/deleteBlog", data);
};

export const returnCategories = (data) => {
  return axiosInstance.post("/returnCategories", data);
};
export const returnSubCategories = (data) => {
  return axiosInstance.post("/returnSubCategories", data);
};
