import useAuth from "./hooks/useAuth";
// theme
import ThemeConfig from "./theme";
import NotistackProvider from "./components/NotistackProvider";

// routes
import Router from "./routes";
import LoadingScreen from "./components/LoadingScreen";

function App() {
  const { isInitialized } = useAuth();
  return (
    <ThemeConfig>
      <NotistackProvider>
        {isInitialized ? <Router /> : <LoadingScreen />}
      </NotistackProvider>
    </ThemeConfig>
  );
}

export default App;
