import { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import DashboardLayout from "../layouts/dashboard";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
};

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

export default function Router() {
  return useRoutes([
    {
      path: "",
      children: [
        {
          path: "",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <GeneralApp to="/dashboard" replace /> },
        { path: "posts", element: <BlogPosts /> },
        { path: "post/:title", element: <BlogPost /> },
        { path: "edit-post/:title", element: <BlogEditPost /> },
        { path: "new-post", element: <BlogNewPost /> },
      ],
    },

    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")));
const BlogNewPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogNewPost"))
);
const BlogEditPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogEditPost"))
);
