import { forwardRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
// import { Helmet } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
// material
import { Box } from "@mui/material";
// utils
// import track from '../utils/analytics';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = "", ...other }, ref) => {
  const { pathname } = useLocation();

  const sendPageViewEvent = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box ref={ref} {...other}>
      {/* <Helmet> */}
      <title>{title}</title>
      {/* </Helmet> */}
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
