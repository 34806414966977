import {ExpandMoreOutlined} from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreOutlined
      },

      styleOverrides: {
        root: {}
      }
    }
  };
}
