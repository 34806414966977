import { createSlice } from "@reduxjs/toolkit";
// utils
import { getAllPost } from "../../api";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: {},
  hasMore: true,
  index: 0,
  step: 11,
};

const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setRecentPosts: (state, action) => {
      state.recentPosts = action.payload;
    },
  },
});

// Reducer
export default blog.reducer;

// Actions
export const { getMorePosts, setPosts, setRecentPosts } = blog.actions;
