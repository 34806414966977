import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  setAccessToken,
  setUser,
} from "../redux/slices/user";
// utils
import { jwtDecode } from "jwt-decode";
import { isValidToken, setSession, isCustomValid } from "../utils/jwt";
import { signIn } from "../api";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const decoded = jwtDecode(accessToken);

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          storeDispatch(setAccessToken(accessToken));
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user: decoded,
            },
          });
          storeDispatch(setIsAuthenticated(true));
          storeDispatch(setUser(decoded));
        } else {
          storeDispatch(setAccessToken(null));
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          storeDispatch(setIsAuthenticated(false));
          storeDispatch(setUser(null));
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await signIn(email, password);
    const { accessToken } = response.data;
    const decoded = jwtDecode(accessToken);
    dispatch({
      type: "LOGIN",
      payload: {
        user: decoded,
      },
    });
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      storeDispatch(setAccessToken(accessToken));
      storeDispatch(setIsAuthenticated(true));
      storeDispatch(setUser(decoded));
    } else {
      storeDispatch(setAccessToken(null));
      storeDispatch(setIsAuthenticated(false));
      storeDispatch(setUser(null));
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
