import { jwtDecode } from "jwt-decode";
//
import axios from "./axios";
import { refreshToken } from "../api";
// ----------------------------------------------------------------------
const secretKey = process.env.REACT_APP_JWT_SECRET;

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const isJwtUsable = async (token) => {
  try {
    const decodedToken = jwtDecode.verify(token, secretKey);

    // Check if 'exp' claim exists
    if (decodedToken.exp) {
      // Get the expiration time from the 'exp' claim
      const expirationTime = new Date(decodedToken.exp * 1000);

      // Check if the token is expired
      if (expirationTime <= new Date()) {
        return false; // Token is expired
      }

      // Check if there is at least 30 seconds before expiration
      const now = new Date();
      const timeUntilExpiration = expirationTime - now;
      const minimumValidityPeriod = 30 * 1000; // 30 seconds in milliseconds

      if (timeUntilExpiration < minimumValidityPeriod) {
        return false; // Not enough time until expiration
      }

      return true; // Token is still usable
    } else {
      // 'exp' claim is not present
      return false;
    }
  } catch (error) {
    if (error.name === "TokenExpiredError") {
      return false; // Token is expired
    } else {
      return false; // Token is invalid for some other reason
    }
  }
};

const readyToken = async ({ token, admin_id }) => {
  const isUsable = await isJwtUsable(token);
  if (isUsable) {
    return token;
  } else {
    const newAccessToken = await refreshToken({ accessToken: token, admin_id });
    return newAccessToken;
  }
};

// const isCustomValid = async (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }

//   const decoded = jwtDecode(accessToken);

//   // const response = await refreshToken({ admin_id: decoded?.adminId });
//   const token = await readyToken({ accessToken, admin_id: decoded?.adminId });
//   console.log(
//     "🚀 ~ file: jwt.js:91 ~ isCustomValid ~ token:",
//     token.data.accessToken
//   );
//   // console.log(
//   //   "🚀 ~ file: jwt.js:36 ~ isCustomValid ~ response:",
//   //   response?.data?.accessToken
//   // );
//   return true;
// };

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = async (accessToken) => {
  const decoded = jwtDecode(accessToken);
  const token = await readyToken({ accessToken, admin_id: decoded?.adminId });
  if (token.data.accessToken) {
    localStorage.setItem("accessToken", token.data.accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${token.data.accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
