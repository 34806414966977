// mock api
// import './_apis_';

// highlight
import "./utils/highlight";

// editor
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// lightbox
// import 'react-image-lightbox/style.css';

// scroll bar
// import 'simplebar/src/simplebar.css';

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

// redux
import store from "./redux/store";
// import { PersistGate } from "redux-persist/lib/integration/react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/JWTContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ReduxProvider store={store}>
      <AuthProvider>
        {/* <PersistGate loading={<div>Loading screen</div>} persistor={persistor}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </PersistGate> */}
      </AuthProvider>
    </ReduxProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
